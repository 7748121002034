module.exports={
"label.account.name":`名字`,
"label.account.copy":`繁体`,
"label.account.phone.length":`繁体`,
"label.account.Start.trial":`開始試用`,
"label.account.employees1000":`繁体`,
"label.account.other.Ways":`繁体`,
"label.account.emailsync.button.cancel":`繁体`,
"label.account.rule":`繁体`,
"label.account.password.update.success":`繁体`,
"label.account.describe":`繁体`,
"label.account.enter.Emailbox":`繁体`,
"label.account.password":`繁体`,
"label.account.problem":`繁体`,
"label.account.Complete.reset":`繁体`,
"label.account.crm.used":`繁体`,
"label.account.report.loading":``,
"label.account.notcustomer":`繁体`,
"label.account.employees501":`繁体`,
"label.account.title.operationManager":`繁体`,
"label.account.Preset":`預置的項目管理工具`,
"label.account.company":`公司`,
"label.account.come":`繁体`,
"label.account.input.password":`繁体`,
"label.account.emailobject.emaildetail.button.back":`繁体`,
"label.account.title.itManager":`繁体`,
"label.account.Modified.successfully":`繁体`,
"label.account.only11":`繁体`,
"label.account.signin":`繁体`,
"label.account.register":`繁体`,
"label.account.adopt":`繁体`,
"label.account.title.hrManager":`繁体`,
"label.account.input.usernames":`繁体`,
"label.account.only.Numbers":`繁体`,
"label.account.title.salesManager":`繁体`,
"label.account.email":`電子郵件`,
"label.account.click":`繁体`,
"label.account.and":`繁体`,
"label.account.mobile":`電話`,
"label.account.Privacy.policy":`繁体`,
"label.account.password.isnull":`繁体`,
"label.account.employees101":`繁体`,
"label.account.remember.name":`繁体`,
"label.account.Select.country":`繁体`,
"label.account.distinguish":`繁体`,
"label.account.fillMobile":`繁体`,
"label.account.Preconfigured":`預配置報表和儀表板`,
"label.account.Select.job":`繁体`,
"label.account.employees21":`繁体`,
"label.account.username":`繁体`,
"label.account.Submit":`繁体`,
"label.account.confirm":`繁体`,
"label.account.contact":`聯系我們`,
"label.account.country":`國家/地區`,
"label.account.username.isnull":`繁体`,
"label.account.forget.password":`繁体`,
"label.account.Loading":`繁体`,
"label.account.goon":`繁体`,
"label.account.Register.trial":`繁体`,
"label.account.title.other":`繁体`,
"label.account.surname":`姓氏`,
"label.account.Fail":`繁体`,
"label.account.Terms.service":``,
"label.account.Select.staff":`繁体`,
"label.account.username.enter":`繁体`,
"label.account.trial":`繁体`,
"label.account.employees1":`繁体`,
"label.account.Quick.registration":`繁体`,
"label.account.tabpage.ok":`繁体`,
"label.account.normal.reregister":`繁体`,
"label.account.batchadd.newbatchpage.save.em":`繁体`,
"label.account..user.title":`職务`,
"label.account.No.credit.card.required":`繁体`,
"label.account.enterprise.Wechat":`繁体`,
"label.account.sample.data":`預加載示例數據`,
"label.account.title.hobbies":`繁体`,
"label.account.platform":`繁体`,
"label.account.sent":`繁体`,
"label.account.Confirm.password":`繁体`,
"label.account.fill":`繁体`,
"label.account.user.resetpw":`繁体`,
"label.account.Spell":`繁体`,
"label.account.identifying.code":`繁体`,
"label.account.staff":`员工`,
"label.account.welcome":`繁体`,
"label.account.online.guidance":`線上提供咨詢與操作指導`,
"label.account.login.CloudCC":`繁体`,
"label.account.Company.name":`繁体`,
"label.account.title.generalManager":`繁体`,
"label.account.enter.user":`繁体`,
"label.account.sent.code":`繁体`,
"label.account.pw.error.text":`繁体`,
"label.account.title.customerManager":`繁体`,
"label.account.label.captcha":`繁体`,
"label.account.Trial.days":`註冊獲得30天免費試用`,
"label.account.please.enter.name":`繁体`,
"label.account.inspect":`繁体`,
"label.account.User.rule":`繁体`,
"label.account.go.Login":`繁体`,
"label.account.Reading.consent":`我同意`,
"label.account.Change.Password2":``,
"label.account.Loginby":``,
"label.account.apply.partner":``,
"label.account.MobileLogin":``,
"label.account.Registration":``,
"label.account.Italy":``,
"label.account.view.details":``,
"label.account.accept":``,
"label.account.China.Mainland":``,
"label.account.NewPassword":``,
"label.account.Mobile.not.empty":``,
"label.account.Please.select":``,
"label.account.market.introduce":``,
"label.account.IOS":``,
"label.account.failsend":``,
"label.account.UpperLowerlAphanumeric.character":``,
"label.account.New.Password1":``,
"label.account.succSend":``,
"label.account.Account.mobile":``,
"label.account.Login.consent":``,
"label.account.fillAreaCode":``,
"label.account.active.login":``,
"label.account.jp":``,
"label.account.partner.success1":``,
"label.account.Reset":``,
"label.account.no.user.info":``,
"label.account.EnterOldPass":``,
"label.account.Germany":``,
"label.account.title.content":`繁体`,
"label.account.Taiwan":``,
"label.account.China.Station":``,
"label.account.sale.introduce":``,
"label.account.Back":``,
"label.account.market":``,
"label.account.section.company":``,
"label.account.check.email":``,
"label.account.characters":``,
"label.account.International":``,
"label.account.britain":``,
"label.account.reg.fail.msg":``,
"label.account.success.reg":``,
"label.account.company.url":``,
"label.account.CannotBeEmpty":``,
"label.account.phone.note":``,
"label.account.second":``,
"label.account.Change.Password":``,
"label.account.Netherlands":``,
"label.account.pleaseAgree":``,
"label.account.copyright":``,
"label.account.letter":``,
"label.account.remember":``,
"label.account.accountNum":``,
"label.account.code.not.empty":``,
"label.account.Spain":``,
"label.account.Alphanumeric":``,
"label.account.obtain":``,
"label.account.company.txt":``,
"label.account.con.browser":``,
"label.account.Android":``,
"label.account.title":``,
"label.account.partner.success2":``,
"label.account.Macao":``,
"label.account.Brazil":``,
"label.account.service.introduce":``,
"label.account.codeFailure":``,
"label.account.AccountLogin":``,
"label.account.pswComplexReqRules":``,
"label.account.UpperLowerlAphanumeric":``,
"label.account.active.username":``,
"label.account.rememberPassword":``,
"label.account.HongKong":``,
"label.account.oldPass":``,
"label.account.Data.storage":``,
"label.account.number":``,
"label.account.Poland":``,
"label.account.service":``,
"label.account.Incorrect.format":``,
"label.account.remember.status":``,
"label.account.sale":``,
"label.account.No.account.binding":``,
"label.account.Russia":``,
"label.account.France":``,
"label.account.partner.content":``,
"label.account.reg.phone.already":``,
"label.account.Confirm":``,
"label.account.VerificationLogin":``,
"label.account.USA":``,
"label.account.mobile.num":``,
"label.account.MinLength":``,
"label.account.change.your.password":``,
"label.account.new.password":``,
"label.account.website.enter":``,
"label.account.pswComplexIsZero":``
}